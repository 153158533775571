import { MatMenu } from "@angular/material/menu";

export class ToolBarButton {
    id: string;
    icons: string[];
    iconPosition = 0;
    tooltips: string[];
    tooltipPosition = 0;
    menuReference: MatMenu = null;
    callback: Function;
    disabled = false;
    active: boolean;
    hidden = false;

    constructor(data: Partial<ToolBarButton>) {
        Object.assign(this, data);
    }

    click() {
        if (this.callback) {
            this.callback(this);
            // Change Icon and Tooltip if there is more than one
            this.iconPosition = (this.iconPosition + 1) % this.icons.length;
            this.tooltipPosition =
                (this.tooltipPosition + 1) % this.tooltips.length;
        }
    }
}
